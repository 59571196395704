'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  # workRegex = /^.*\/pc\/[^\/]+\/.*$/
  workRegex = /^.*theclinic-system\.com.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 2

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  fixContact = $('.fix-navi-contact')
  topBtn = $('.r-pagetop')
  #最初はボタンを隠す
  fixContact.hide()
  topBtn.hide()
  #スクロールが300に達したらボタンを表示させる
  $(window).scroll ->
    if $(this).scrollTop() > 300
      fixContact.fadeIn()
      topBtn.fadeIn()
    else
      fixContact.fadeOut()
      topBtn.fadeOut()
    return
  topBtn.click ->
    $('body,html').animate
      scrollTop: 0
    , 500
    false

  offsetY = -70
  time = 500
  $('.m-anchor-item a[href^="#"]').on 'click', ->
    target = $(@hash)
    if !target.length
      return
    targetY = target.offset().top + offsetY
    # スクロールアニメーション
    $('html,body').animate { scrollTop: targetY }, time, 'swing'
    window.history.pushState null, null, @hash
    false

  quickMail =
    ($insertTarget, path, conf)->
      $target = $insertTarget

      $target.load(path,
        (data)->
          $form = $target.find('form')
          conf = conf

          conf.customAddError =
            ()->
              return
          conf.customScrollAdjust =
            ()->
              return $(window).scrollTop() + 10
          conf.customClearError =
            ()->
              $.ajax({
                url:$form.attr('action')
                type:$form.attr('method')
                data:$form.serialize()
                dataType:'html'
                timeout:10000
                beforeSend:
                  (xhr,settings)->
                    return
                complete:
                  (xhr,status)->
                    return
                success:
                  (result,status,xhr)->
                    inputObj = result
                    $target.empty()
                    $target.append(result)

                    $target.find('a').on('click',
                      (e)->
                        e.preventDefault()
                        $target.empty()
                        $target.load(path,
                          ()->
                            $('.formError').each(
                              ()->
                                if $(this).attr('class').indexOf($form.attr('id')) isnt -1
                                  $(this).remove()
                            )

                            quickMail($target, path, conf)
                        )
                    )
              })
              return

          $form.exValidation(conf)

          $(window).on('scroll resize',
            ()->
              $('.formError').each(
                ()->
                  position = $('#'+$(this).attr('id').replace('err_', '')).offset()
                  if $(this).attr('id').indexOf('s-') isnt -1
                    if $('.s-fix-contents').hasClass('is-fixed')
                      position = $('.is-fixed #'+$(this).attr('id').replace('err_', '')).offset()
                      $(this).css({
                        'position': 'fixed'
                        'top': position.top - $(window).scrollTop() - $(this).outerHeight()
                        'left':  position.left + 176
                      })
                    else
                      $(this).css({
                        'position': 'absolute'
                        'top': position.top - $(this).outerHeight()
                        'left':  position.left + 176
                      })
              )
          )
      )

  if $('#sub').length isnt 0
    $('.sub-concierge-tab-item > a').on('focus',
      (e)->
        $(this).off('click.smoothScroll')
    )

    $('.sub-concierge-tab-item > a').on('click',
      (e)->
        e.preventDefault()

        $target = $($(this).attr('href'))
        $(this).parents('.sub-concierge-tab').find('.sub-concierge-tab-item').removeClass('is-active')
        $(this).parent().addClass('is-active')
        $(this).parents('.sub-concierge-tab').siblings('.sub-concierge-contents').find('.sub-concierge-contents-item').removeClass('is-active')
        $target.addClass('is-active')

        if $(this).attr('href').indexOf('mail') is -1
          $('.formError[class*=s-]').each(
            ()->
              $(this).hide()
          )
        else
          if $('.sub-concierge-mail').find('form').length isnt 0 and $('.formError[class*=s-]').css('top') isnt 'auto'
            $('#sub-concierge-mail-form').find('.err').each(
              ()->
                $('#err_'+$(this).attr('id')).show()
            )
    )

    $(window).on('load',
      ()->
        fixedContents()
    )

    $formWrapper = $('#sub-concierge-mail').find('.form-wrapper')

    conf = {
      rules:{
        'sub-concierge-name': 'chkrequired'
        'sub-concierge-email': 'chkrequired chkemail'
        'sub-concierge-text': 'chkrequired'
      },
      errFocus:true,
      errTipPos:'right',
      errMsgPrefix:'※',
      errZIndex:1100,
    }

    quickMail($formWrapper, url + 'form/sub/', conf)

  $(window).on('load'
    ()->
      footer_svg = document.getElementById('footer')
      footer_svg = footer_svg.getElementsByTagName('object')

      if footer_svg.length isnt 0
        for i in [0..footer_svg.length - 1]
          $(footer_svg[i]).addClass('is-active')
          $(footer_svg[i].contentDocument).find('svg').find('path')
  )
  if $id != 'top'
    if $('#g-navi')?
      firstDownFlg = true
      firstUpFlg = false

      $('.g-navi').headroom(
        offset: $('#header').height()
        classes:
          initial: 'animated'
          pinned: 'slideDown'
          unpinned: 'slideUp'
        onPin: ()->
          firstUpFlg = true
          if !firstDownFlg
            $('.g-navi').removeClass('slideUpFirst')
        onUnpin: ()->
        onTop: ()->
          firstDownFlg = true
          $('.g-navi').removeClass('slideDownFirst')

          $('.g-navi-item').find('img').each(()->
            $(this).attr('src', $(this).attr('src').replace('_f.gif', '.gif'))
          )
        onNotTop: ()->
          if firstDownFlg
            $('.g-navi').addClass('slideUpFirst')
            firstDownFlg = false

          $('.g-navi-item').find('img').each(()->
            $(this).attr('src', $(this).attr('src').replace('.gif', '_f.gif'))
          )
      )

      $(window).scroll(()->
        if firstUpFlg and $(this).scrollTop() <= $('#header').height() + $('.g-navi').height()
          $('.g-navi').addClass('slideDownFirst')
          firstUpFlg = false
      )

  # 固定コンテンツ制御
  fixedContents =
    ()->
      mainHeight = $('#main').height()
      subHeight = $('#sub').outerHeight(true)
      footerHeight = $('#footer').outerHeight()

      if subHeight < mainHeight
        if $id == 'top'
          $('#sub').css('min-height', $('.m-contents-wrapper').outerHeight(true) - 493)
        else
          $('#sub').css('min-height', $('.m-contents-wrapper').outerHeight(true))

        mainContentsHeight = $('.m-contents-wrapper').outerHeight(true)
        subContentsHeight = $('.s-contents-wrapper').outerHeight(true)
        windowHeight = $(window).height()
        # 30 = メニュー下マージン
        adjustMargin = $('.gnavi').outerHeight() + 30

        $fixContents = $('.s-fix-contents')
        fixContentsHeight = $('.s-fix-contents').outerHeight(true)
        fixContentsPos = $fixContents.offset().top - adjustMargin

        fixFlg = false
        bottomFlg = false

        $(window).scroll(
          ()->
            # メインコンテンツの高さが変わった時時再度高さ適応
            currentSubHeight = $('#sub').outerHeight(true)
            currentMainContentsHeight = $('.m-contents-wrapper').outerHeight(true)

            if currentMainContentsHeight > mainContentsHeight
              $('#sub').css('min-height', currentMainContentsHeight)

            currentSubContentsHeight = $('.s-contents-wrapper').outerHeight(true)

            # メニュー開閉等で位置が変わった時再計算
            currentFixContentsPos = $('.s-fix-contents').offset().top - adjustMargin

            if $('.s-fix-contents').not('.is-fixed,.is-bottom').length isnt 0
              #currentFixContentsPos = $('.s-fix-contents').not('.is-fixed,.is-bottom').offset().top - adjustMargin

              if currentFixContentsPos > fixContentsPos
                fixContentsPos = currentFixContentsPos

            # サブコンテンツの方が高さが低い時のみ実行
            if currentSubContentsHeight < currentMainContentsHeight
              scrollPos = $(window).scrollTop()
              fixContentsBottomPos = $('#container').height() - (scrollPos + windowHeight) - $('#footer').outerHeight() - ((adjustMargin + fixContentsHeight) - windowHeight)

              if scrollPos > fixContentsPos
                if not fixFlg and not bottomFlg
                  $fixContents.addClass('is-fixed')
                  fixFlg = true
              else
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

              if fixContentsBottomPos < 0
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

                  if not bottomFlg
                    $fixContents.addClass('is-bottom')
                    bottomFlg = true
              else
                if bottomFlg
                  $fixContents.removeClass('is-bottom').addClass('is-fixed')
                  bottomFlg = false
        )
      else
        $('.m-contents-wrapper').css('min-height', $('#sub').outerHeight())

  if $('#g-navi')?
    firstDownFlg = true
    firstUpFlg = false
  
    $('.g-navi').headroom(
      offset: $('#main-visual').height()
      classes:
        initial: 'animated'
        pinned: 'slideDown'
        unpinned: 'slideUp'
      onPin: ()->
        firstUpFlg = true
        if !firstDownFlg
          $('.g-navi').removeClass('slideUpFirst')
      onUnpin: ()->
      onTop: ()->
        firstDownFlg = true
        $('.g-navi').removeClass('slideDownFirst')
  
        $('.g-navi-item').find('img').each(()->
          $(this).attr('src', $(this).attr('src').replace('_f.gif', '.gif'))
        )
      onNotTop: ()->
        if firstDownFlg
          $('.g-navi').addClass('slideUpFirst')
          firstDownFlg = false
  
        $('.g-navi-item').find('img').each(()->
          $(this).attr('src', $(this).attr('src').replace('.gif', '_f.gif'))
        )
    )
  
    $(window).scroll(()->
      if firstUpFlg and $(this).scrollTop() <= $('#main-visual').height() + $('.g-navi').height()
        $('.g-navi').addClass('slideDownFirst')
        firstUpFlg = false
    )
  
  $('.m-case-slide-list').slick
    centerMode: true
    centerPadding: '75px'
    slidesToShow: 1
    autoplay: true
    dots: true
  
  $('.m-tab-item a[href^="#tab-contents"]').click ->
    $('.m-tab-contents-item').hide()
    $(this.hash).fadeIn(400)
    $('.m-tab-item a').parent().removeClass 'is-active'
    $(this).parent().toggleClass 'is-active'
    false
  
)
